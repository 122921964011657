import React from 'react';
import { useLocation } from '@reach/router';
import { LayoutKometa } from '../../../layouts/LayoutKometa';
import { CodeBlock } from '../../../components/CodeBlock';
import { MaterialPalette } from '../../../palettes/material';
import '../../../css/montserrat.css';
import { ProductKometa } from '../../../products/kometa';
import { ProductBreadcrumbs } from '../../../components/ProductBreadcrumbs';
import { ProductSectionTitle } from '../../../components/ProductSectionTitle';
import { ProductSectionParagraph } from '../../../components/ProductSectionParagraph';
import { ProductColorPalette } from '../../../components/ProductColorPalette';
import { ProductSectionExternalLink } from '../../../components/ProductSectionLink';
import { ProductFontWeights } from '../../../components/ProductFontWeights';

const config = `const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  theme: {
    extend: {
      fontFamily: {
        sans: ['Montserrat', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        red: {
          '50': '#ffebee',
          '100': '#ffcdd2',
          '200': '#ef9a9a',
          '300': '#e57373',
          '400': '#ef5350',
          '500': '#f44336',
          '600': '#e53935',
          '700': '#d32f2f',
          '800': '#c62828',
          '900': '#b71c1c',
          'accent-100': '#ff8a80',
          'accent-200': '#ff5252',
          'accent-400': '#ff1744',
          'accent-700': '#d50000',
        },
        purple: {
          50: '#f3e5f5',
          100: '#e1bee7',
          200: '#ce93d8',
          300: '#ba68c8',
          400: '#ab47bc',
          500: '#9c27b0',
          600: '#8e24aa',
          700: '#7b1fa2',
          800: '#6a1b9a',
          900: '#4a148c',
          'accent-100': '#ea80fc',
          'accent-200': '#e040fb',
          'accent-400': '#d500f9',
          'accent-700': '#aa00ff',
        },
        'deep-purple': {
          50: '#ede7f6',
          100: '#d1c4e9',
          200: '#b39ddb',
          300: '#9575cd',
          400: '#7e57c2',
          500: '#673ab7',
          600: '#5e35b1',
          700: '#512da8',
          800: '#4527a0',
          900: '#311b92',
          'accent-100': '#b388ff',
          'accent-200': '#7c4dff',
          'accent-400': '#651fff',
          'accent-700': '#6200ea',
        },
        teal: {
          50: '#e0f2f1',
          100: '#b2dfdb',
          200: '#80cbc4',
          300: '#4db6ac',
          400: '#26a69a',
          500: '#009688',
          600: '#00897b',
          700: '#00796b',
          800: '#00695c',
          900: '#004d40',
          'accent-100': '#a7ffeb',
          'accent-200': '#64ffda',
          'accent-400': '#1de9b6',
          'accent-700': '#00bfa5',
        },
        indigo: {
          50: '#e8eaf6',
          100: '#c5cae9',
          200: '#9fa8da',
          300: '#7986cb',
          400: '#5c6bc0',
          500: '#3f51b5',
          600: '#3949ab',
          700: '#303f9f',
          800: '#283593',
          900: '#1a237e',
          'accent-100': '#8c9eff',
          'accent-200': '#536dfe',
          'accent-400': '#3d5afe',
          'accent-700': '#304ffe',
        },
        pink: {
          50: '#fce4ec',
          100: '#f8bbd0',
          200: '#f48fb1',
          300: '#f06292',
          400: '#ec407a',
          500: '#e91e63',
          600: '#d81b60',
          700: '#c2185b',
          800: '#ad1457',
          900: '#880e4f',
          'accent-100': '#ff80ab',
          'accent-200': '#ff4081',
          'accent-400': '#f50057',
          'accent-700': '#c51162',
        },
        blue: {
          50: '#e3f2fd',
          100: '#bbdefb',
          200: '#90caf9',
          300: '#64b5f6',
          400: '#42a5f5',
          500: '#2196f3',
          600: '#1e88e5',
          700: '#1976d2',
          800: '#1565c0',
          900: '#0d47a1',
          'accent-100': '#82b1ff',
          'accent-200': '#448aff',
          'accent-400': '#2979ff',
          'accent-700': '#2962ff',
        },
        'light-blue': {
          50: '#e1f5fe',
          100: '#b3e5fc',
          200: '#81d4fa',
          300: '#4fc3f7',
          400: '#29b6f6',
          500: '#03a9f4',
          600: '#039be5',
          700: '#0288d1',
          800: '#0277bd',
          900: '#01579b',
          'accent-100': '#80d8ff',
          'accent-200': '#40c4ff',
          'accent-400': '#00b0ff',
          'accent-700': '#0091ea',
        },
        cyan: {
          50: '#e0f7fa',
          100: '#b2ebf2',
          200: '#80deea',
          300: '#4dd0e1',
          400: '#26c6da',
          500: '#00bcd4',
          600: '#00acc1',
          700: '#0097a7',
          800: '#00838f',
          900: '#006064',
          'accent-100': '#84ffff',
          'accent-200': '#18ffff',
          'accent-400': '#00e5ff',
          'accent-700': '#00b8d4',
        },
        gray: {
          50: '#fafafa',
          100: '#f5f5f5',
          200: '#eeeeee',
          300: '#e0e0e0',
          400: '#bdbdbd',
          500: '#9e9e9e',
          600: '#757575',
          700: '#616161',
          800: '#424242',
          900: '#212121',
        },
        'blue-gray': {
          50: '#eceff1',
          100: '#cfd8dc',
          200: '#b0bec5',
          300: '#90a4ae',
          400: '#78909c',
          500: '#607d8b',
          600: '#546e7a',
          700: '#455a64',
          800: '#37474f',
          900: '#263238',
        },
        green: {
          50: '#e8f5e9',
          100: '#c8e6c9',
          200: '#a5d6a7',
          300: '#81c784',
          400: '#66bb6a',
          500: '#4caf50',
          600: '#43a047',
          700: '#388e3c',
          800: '#2e7d32',
          900: '#1b5e20',
          'accent-100': '#b9f6ca',
          'accent-200': '#69f0ae',
          'accent-400': '#00e676',
          'accent-700': '#00c853',
        },
        'light-green': {
          50: '#f1f8e9',
          100: '#dcedc8',
          200: '#c5e1a5',
          300: '#aed581',
          400: '#9ccc65',
          500: '#8bc34a',
          600: '#7cb342',
          700: '#689f38',
          800: '#558b2f',
          900: '#33691e',
          'accent-100': '#ccff90',
          'accent-200': '#b2ff59',
          'accent-400': '#76ff03',
          'accent-700': '#64dd17',
        },
        lime: {
          50: '#f9fbe7',
          100: '#f0f4c3',
          200: '#e6ee9c',
          300: '#dce775',
          400: '#d4e157',
          500: '#cddc39',
          600: '#c0ca33',
          700: '#afb42b',
          800: '#9e9d24',
          900: '#827717',
          'accent-100': '#f4ff81',
          'accent-200': '#eeff41',
          'accent-400': '#c6ff00',
          'accent-700': '#aeea00',
        },
        amber: {
          50: '#fff8e1',
          100: '#ffecb3',
          200: '#ffe082',
          300: '#ffd54f',
          400: '#ffca28',
          500: '#ffc107',
          600: '#ffb300',
          700: '#ffa000',
          800: '#ff8f00',
          900: '#ff6f00',
          'accent-100': '#ffe57f',
          'accent-200': '#ffd740',
          'accent-400': '#ffc400',
          'accent-700': '#ffab00',
        },
        yellow: {
          50: '#fffde7',
          100: '#fff9c4',
          200: '#fff59d',
          300: '#fff176',
          400: '#ffee58',
          500: '#ffeb3b',
          600: '#fdd835',
          700: '#fbc02d',
          800: '#f9a825',
          900: '#f57f17',
          'accent-100': '#ffff8d',
          'accent-200': '#ffff00',
          'accent-400': '#ffea00',
          'accent-700': '#ffd600',
        },
        orange: {
          50: '#fff3e0',
          100: '#ffe0b2',
          200: '#ffcc80',
          300: '#ffb74d',
          400: '#ffa726',
          500: '#ff9800',
          600: '#fb8c00',
          700: '#f57c00',
          800: '#ef6c00',
          900: '#e65100',
          'accent-100': '#ffd180',
          'accent-200': '#ffab40',
          'accent-400': '#ff9100',
          'accent-700': '#ff6d00',
        },
        'deep-orange': {
          50: '#fbe9e7',
          100: '#ffccbc',
          200: '#ffab91',
          300: '#ff8a65',
          400: '#ff7043',
          500: '#ff5722',
          600: '#f4511e',
          700: '#e64a19',
          800: '#d84315',
          900: '#bf360c',
          'accent-100': '#ff9e80',
          'accent-200': '#ff6e40',
          'accent-400': '#ff3d00',
          'accent-700': '#dd2c00',
        },
        brown: {
          50: '#efebe9',
          100: '#d7ccc8',
          200: '#bcaaa4',
          300: '#a1887f',
          400: '#8d6e63',
          500: '#795548',
          600: '#6d4c41',
          700: '#5d4037',
          800: '#4e342e',
          900: '#3e2723',
        },
      },
      spacing: {
        '7': '1.75rem',
        '9': '2.25rem',
        '28': '7rem',
        '80': '20rem',
        '96': '24rem',
      },
      height: {
        '1/2': '50%',
      },
      scale: {
        '30': '.3',
      },
      boxShadow: {
        outline: '0 0 0 3px rgba(101, 31, 255, 0.4)',
      },
    },
  },
  variants: {
    scale: ['responsive', 'hover', 'focus', 'group-hover'],
    textColor: ['responsive', 'hover', 'focus', 'group-hover'],
    opacity: ['responsive', 'hover', 'focus', 'group-hover'],
    backgroundColor: ['responsive', 'hover', 'focus', 'group-hover'],
  },
  plugins: [],
};`;

const KometaConfigPage = () => {
  const location = useLocation();

  return (
    <LayoutKometa>
      <ProductBreadcrumbs
        productTitle={ProductKometa.title}
        productUrl={ProductKometa.url}
        breadcrumbs={[{ url: location.pathname, title: 'Tailwind Config' }]}
      />
      <section className="mb-10">
        <ProductSectionTitle>The Config</ProductSectionTitle>
        <ProductSectionParagraph>
          This UI kit doesn't rely on any 3rd party plugins, or anything too
          custom. We've included a custom color palette & font family, and a
          couple of spacings and variants. Just copy the entire config, and
          you're ready to go.
          <br />
          <br />
          Note that {ProductKometa.title} is built on top of Tailwind CSS 1.4.1.
        </ProductSectionParagraph>
        <CodeBlock language="js" stripLast={false} maxHeight="30rem">
          {config}
        </CodeBlock>
        <ProductSectionParagraph>
          Below you'll find more info about the color palette and the font
          family.
        </ProductSectionParagraph>
      </section>
      <section className="mb-10">
        <ProductSectionTitle>Color Palette</ProductSectionTitle>
        <ProductSectionParagraph>
          This UI kit was built on top of{' '}
          <a
            href="https://material.io/design/color/the-color-system.html"
            title="Material Design color palette"
            aria-label="Material Design color palette"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium text-indigo-600 transition-colors duration-200 hover:text-indigo-700 hover:underline"
          >
            Material Design color palette
          </a>
          . Teal and deep purple are the two main colors used in all components,
          however we've included the full Material Design color palette in the
          config, so you'll be able to easily switch the colors to match your
          brand and identity.
        </ProductSectionParagraph>
        <ProductColorPalette palette={MaterialPalette} />
      </section>
      <section>
        <ProductSectionTitle className="mb-2 text-3xl font-semibold">
          Font Family
        </ProductSectionTitle>
        <ProductSectionParagraph>
          Our font of choice for this UI kit was{' '}
          <ProductSectionExternalLink
            href="https://fonts.google.com/specimen/Montserrat"
            title="Montserrat"
            aria-label="Montserrat"
          >
            Montserrat
          </ProductSectionExternalLink>
          . It is available for free on{' '}
          <ProductSectionExternalLink
            href="https://fonts.google.com/specimen/Montserrat"
            title="Montserrat"
            aria-label="Montserrat"
          >
            Google Fonts
          </ProductSectionExternalLink>
          .
        </ProductSectionParagraph>
        <ProductSectionParagraph>
          It can be easily installed by copying this code into your CSS:
        </ProductSectionParagraph>
        <CodeBlock language="scss" stripLast={false}>
          {`@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');`}
        </CodeBlock>
        <ProductSectionParagraph>
          Or by copying this code into the {'<head>'} of your HTML:
        </ProductSectionParagraph>
        <CodeBlock language="html" stripLast={false}>
          {`<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">`}
        </CodeBlock>
        <ProductFontWeights fontFamily="Montserrat">
          Montserrat is a cool font
        </ProductFontWeights>
      </section>
    </LayoutKometa>
  );
};

export default KometaConfigPage;
