import React from 'react';

export const ProductSectionExternalLink = ({ children, ...rest }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    className="font-medium text-indigo-600 transition-colors duration-200 hover:text-indigo-700 hover:underline"
    {...rest}
  >
    {children}
  </a>
);
