import React from 'react';

export const ProductFontWeights = ({ children, fontFamily }) => (
  <div>
    {[
      'font-hairline',
      'font-thin',
      'font-light',
      'font-normal',
      'font-medium',
      'font-semibold',
      'font-bold',
      'font-extrabold',
      'font-black',
    ]
      .reverse()
      .map((fontClassName, index) => (
        <div key={index} className="pb-4 mb-4 border-b">
          <p className="mb-2 text-sm text-gray-800">{fontClassName}</p>
          <p
            style={{
              fontFamily,
            }}
            className={`${fontClassName} leading-none text-xl sm:text-2xl`}
          >
            {children}
          </p>
        </div>
      ))}
  </div>
);
