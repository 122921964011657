import React from 'react';

export const ProductColorPalette = ({ palette }) => (
  <div className="grid gap-5 row-gap-8 sm:grid-cols-2 xl:grid-cols-3">
    {Object.keys(palette).map((colorKey, index) => (
      <div key={index}>
        <p className="mb-4 text-lg font-medium capitalize">
          {colorKey.replace(/-/g, ' ')}
        </p>
        <div className="overflow-hidden rounded shadow-lg">
          {Object.keys(palette[colorKey]).map((variant, colorIndex) => (
            <div
              key={colorIndex}
              style={{
                backgroundColor: palette[colorKey][variant].color,
                color: palette[colorKey][variant].textColor,
              }}
              className="flex items-center justify-between px-4 py-3 text-sm tracking-wide"
            >
              <p>
                {colorKey}-{variant}
              </p>
              <p className="uppercase">{palette[colorKey][variant].color}</p>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);
